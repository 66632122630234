import { TSourceManagements } from '../type/source-management'
import { request } from './axios'

export const SourceManagement = {
  get: async (search_key = '', page_number = 1): Promise<TSourceManagements> => {
    const searchParam = search_key ? `&search=${search_key}` : ''
    const {
      data: { patient_sources, max_page_size, page_no, total_count, total_pages },
    } = await request({
      url: `/category-classification/patient-source/management/?page_number=${page_number}${searchParam}`,
    })

    return {
      patient_sources,
      max_page_size,
      page_no,
      total_count,
      total_pages,
    }
  },
  archive: async (source_name: string): Promise<void> => {
    await request({
      method: 'delete',
      url: `/category-classification/patient-source/management/?patient_source_name=${source_name}`,
    })
  },
}
